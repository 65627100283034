import { Injectable } from '@angular/core';
import { GeralApi } from '@core/api/ords';
import { Dominio, DominioItem, Orientacao, RegistroErro } from '@core/api/ords/model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export enum TipoDominio {
  SIMULADO = 'IND_TIPO_SIMULADO',
  TOPICO = 'IND_TIPO_TOPICO',
  PRECISAO = 'IND_MEDICAO_PRECISAO',
  SITUACAO = 'IND_SITUACAO_MEDICAO',
  FORMA = 'IND_FORMA_MEDICAO',
  INDICADOR = 'IND_TIPO_INDICADOR',
  TIPO_FILTRO = 'CFG_TIPO_FILTRO',
  MON_CR_CRITICA_GUIA_RESUMO = 'MON_CR_CRITICA_GUIA_RESUMO',
  MON_REGISTRO_SITUACAO = 'MON_REGISTRO_SITUACAO',
  IND_FAIXA_ETARIA_INDICADOR_CONSULTA = 'IND_FAIXA_ETARIA_INDICADOR_CONSULTA',
  MON_TIPO_GUIA = 'MON_TIPO_GUIA',
}

@Injectable({
  providedIn: 'root',
})
export class GeralService {
  private dominios = new BehaviorSubject<Dominio[]>([]);
  private dominiosLoaded = new BehaviorSubject<boolean>(false);
  private orientacoesCache: { [key: string]: Orientacao } = {};

  constructor(private geralApi: GeralApi) {}

  getDominiosLoaded(): Observable<boolean> {
    return this.dominiosLoaded.asObservable();
  }

  fetchDominios(): void {
    this.geralApi.fetchDominios().subscribe(dominios => {
      this.dominios.next(dominios);
      this.dominiosLoaded.next(true);
    });
  }

  getDominioPorTipo(tipo: TipoDominio | string) {
    return this.dominios.asObservable().pipe(
      filter(dominios => !!dominios.length),
      map(dominios => {
        const tipos = dominios.filter(dominio => dominio.cd_dominio === tipo.toString());
        return tipos[0].itens;
      }),
    );
  }

  getDominioMapPorTipo(tipo: string | TipoDominio): { [key: string]: DominioItem } {
    const dominio = this.dominios.value.find(dom => dom.cd_dominio == tipo);
    if (!dominio) {
      return {};
    }
    return dominio.itens.reduce((acc, item) => {
      acc[item.cd_valor_valido] = item;
      return acc;
    }, {});
  }

  getDominioItem(id: string | number, tipo: string | TipoDominio): DominioItem {
    if (id == null || !this.dominios.value.length) {
      return null;
    }
    const dominio = this.dominios.value.find(dom => dom.cd_dominio == tipo);
    const item = dominio?.itens.find(it => it.cd_valor_valido == id);
    return item;
  }

  buildNomeDeDominio(id: string | number, tipo: string | TipoDominio, valueOnly?: boolean): string {
    if (id == null || !this.dominios.value.length) {
      return '';
    }
    const item = this.getDominioItem(id, tipo);
    if (!item) {
      return '' + id;
    }
    if (valueOnly) {
      return item.ds_valor_valido;
    }
    return item.cd_valor_valido + ' - ' + item.ds_valor_valido;
  }

  getOrientacao(codigo: string) {
    if (this.orientacoesCache[codigo]) {
      return of(this.orientacoesCache[codigo]);
    }
    return this.geralApi.fetchOrientacao(codigo).pipe(
      tap(orientacao => {
        this.orientacoesCache[codigo] = orientacao;
      }),
    );
  }

  getAnosBases(regans: string) {
    return this.geralApi.fetchAnosBases(regans);
  }

  getMetodoCalculoIndicador(cd_indicador: string) {
    return this.geralApi.fetchMetodoCalculoIndicador(cd_indicador);
  }

  getConfigSuporteVisible() {
    return this.geralApi.fetchParametroConfig('APL_IB_HABILITA_SUPORTE').pipe(map(value => value == '1'));
  }

  getOperadoraModulos(regans: string, anoBase: number) {
    return this.geralApi.fetchOperadoraModulos(regans, anoBase);
  }

  registrarErro(erro: RegistroErro) {
    // console.log('[Geral] registrarErro:', erro);
    this.geralApi.registrarErro(erro).subscribe();
  }
}
